.rating-description {
    transition: all 0.4s;
    overflow:hidden;
}

.activated{
    height:24rem;
}

.inactivated{
    height: 4rem;
    overflow:hidden;
}