.intro-box{
    text-align:center;
  padding: 1rem ;
  margin: 1rem ;
}

.particulars{
    text-align: right;
}

.profile{
    display:flex;
    flex-direction: row;
}

.Radar{
    display: block;
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .Radar{
    display: none;
  }
  
}

.skills-aos-elements:focus{
    background-color: #87b1de;
}
.label-lan{
    display: inline;
    padding: .3rem .6rem;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}