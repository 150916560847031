.App-intro {
    font-size: large;
}

.Main-Body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Body-text {
    text-align: center;
    margin: 1rem !important;
}

/*Sidebar CSS*/

.active-sidetab {
    background-color: #3271B5 !important;
    width: inherit;
    color: white !important;
}

.sidebar-menu {
    min-width: inherit;
}

.sidebar-tab-text {
    margin-left: 1rem;
}

/*Sidebar CSS*/

/*Introjs CSS*/
.introjs-tooltip {
    min-width: 250px !important;
}

.introjs-tooltip::before {
    background: url(https://cdn.jaga-me.com/profiles/v1/catherine.square.jpg) no-repeat;
    display: block;
    background-size: 100%;
    width: 70px;
    height: 70px;
    content: "";
    margin: auto;
    border-radius: 35px;
}

/*.introjs-helperNumberLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    top: -16px;
    left: 220px !important;
}*/

.introjs-helperNumberLayer {
    visibility: hidden;
}

/* bring user-menu in front of introjs box */
.navbar-fixed-top.introjs-fixParent {
    position: relative;
}

.navbar-fixed-top.introjs-fixParent span {
    color: black;
}

/*Profile CSS*/

.Profile {
    background-color: inherit;
    text-align: center;
}

.Home-main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shadow {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
}

.main-header {
    width: 100%;
}

.content {
    background-color: #ecf0f5;
    -webkit-overflow-scrolling: touch;
    min-height: 250px;
    height: 100%;
    width: 100%;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.Main-page {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.content-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

.box.box-primary {
    border-top-color: #87b1de;
}

.logo {
    background-color: #87b1de !important;
}

.main-header .navbar, .user-header {
    background: linear-gradient(to right, #87b1de, #3271B5) !important;
}

.underconstruction {
    color: lightgray;
}

.main-header .navbar .nav > li > a:hover, .main-header .navbar .nav > li > a:active, .main-header .navbar .nav > li > a:focus, .main-header .navbar .nav .open > a, .main-header .navbar .nav .open > a:hover, .main-header .navbar .nav .open > a:focus, .main-header .navbar .nav > .active > a {
    background: hsl(210, 57%, 60%) !important;
    color: #f6f6f6;
}

.main-header .navbar .sidebar-toggle:hover {
    color: #f6f6f6;
    background: hsl(210, 57%, 60%) !important;
}

.main-header .navbar .sidebar-toggle:active {
    color: #f6f6f6;
    background: hsl(210, 57%, 60%) !important;
}

.form-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-sidebar {
    position: fixed !important;
}

.callout.callout-info {
    background-color: #7FB28F !important;
}

.callout.callout-info {
    border-color: #73AB84 !important;
}

.callout {
    margin: 0px !important;
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    height: 10%;
}

.modal {
    overflow-y: scroll;
    display: block;
    padding-right: 15px;
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    height: 10%;
}

.content-wrapper, .right-side {
    min-height: 100%;
    background-color: #ecf0f5;
    z-index: 800;
}

.referral-iframe {
    position: relative;
    width: 100%;
    height: 433px;
}

.pending {
    text-align: center;
}

.div-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.loading-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.alert-position {
    position: absolute;
    top: 5rem;
    right: 1rem;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {

    .content-wrapper {
        margin-left: 0;
        height: 100%;
    }

    .main-header .navbar {
        margin: 0;
    }
}

@media (min-width: 768px) {
    .modal-title-time {
        text-align: center;
    }

    .show-case-view-tablet {
        display: block;
    }

    .hide-case-view-tablet {
        display: none;
    }
}

@media (max-width: 767px) {
    .patient-header {
        min-height: 45px;
    }

    .main-sidebar, .left-side {
        padding-top: 50px !important;
    }

    .main-header .navbar, .user-header {
        background: #87b1de !important;
    }

    .jaga-me-logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex !important;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .modal {
        padding-right: 0px;
    }

    .modal-dialog {
        margin: 0px !important;
    }

    /* .modal-content{
        height: 100%;
    } */

    .show-case-view-tablet {
        display: none;
    }

    .hide-case-view-tablet {
        display: block;
    }
}

.jaga-me-logo {
    display: none;
}

.introjs-fixParent {
    z-index: auto !important;
    opacity: 1 !important;
    position: absolute !important;
    transform: none !important;
}

.active-case-tab {
    color: #87b1de;
}

.updated {
    position: fixed;
    right: 2rem;
    top: 70;
    left: auto;
    z-index: 10050;
}

.nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: #87b1de !important;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #CCC;
}

.sm-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #CCC;
}

.swipeleft-enter {
    transform: translate(300px, 0px);
}

.swipeleft-enter.swipeleft-enter-active {
    transform: translate(0px, 0px);
    transition: transform 300ms ease-in;
}

.swipeleft-exit {
    transform: translate(0px, 0px);
}

.swipeleft-exit.fade-exit-active {
    transform: translate(-400px, 0px);
    transition: transform 300ms ease-in;
}

.fullsize {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.full-size {
    height: 100%;
    width: 100%;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
}

.bg-jm-pink {
    background-color: #f7a3a6;
    font-weight: bold;
    color: white !important;
}

.bg-jm-pink:hover {
    background-color: #f38487 !important;
    box-shadow: none !important;
}

.service-tab{
    border-bottom: solid 1px #e1e3e9;
}

.Select-menu-outer{
    z-index: 4000;
}

/* hacks */

.flatpickr-weekdaycontainer {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 28px;
}