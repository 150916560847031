body {
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;  
  min-height:100% !important;
}

#root{
  height:100%;
  min-width: inherit;
}

*{
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Open Sans', sans-serif !important;
}
