.loading-logo{
    height: 200px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    position: relative;
  }
  .loading-logo svg{
    max-height: 120px;
    max-width: 120px;
  }
  .loading-logo img{
    position: absolute;
    height: 60px;
    width: 60px;
    top: 30px;
    left: 68px;
  }
  .loading-logo figcaption{
    font-size: 16px;
    font-weight: 700;
    color: #777;
  }